import { ICard } from '../../meetups-workshops/interfaces/meetups-workshops.interface';

export const cardsData: ICard[] = [
  {
    id: 1,
    imageUrl: 'https://cdn-images.island-conference.com/meetups/fan_sites.png',
    sector: 'meetups/fan-sites',
    phrase1: 'FAN SITES',
    phrase2: 'MEETUP',
    description: 'Exclusive strategies and networking at our creator workshop!',
  },
  {
    id: 2,
    imageUrl: 'https://cdn-images.island-conference.com/meetups/seo-workshops.png',
    sector: 'meetups/seo-workshops',
    phrase1: 'SEO',
    phrase2: 'WORKSHOPS',
    description: 'Expand your SEO knowledge and drive organic traffic to your brand!',
  },
  {
    id: 3,
    imageUrl: 'https://cdn-images.island-conference.com/meetups/miconos.png',
    sector: 'miconos',
    phrase1: 'MICONOS',
    phrase2: 'MEETUP',
    description: 'Mingle with CEOs, Business Owners, and Super Affiliates at a once-in-a-lifetime trip!',
  },
];
