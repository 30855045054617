import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cardsExhibitData } from '../meetups-fan-sites/data/fan-sites-exhibit-data';
import { ICard, IEmptySchedule, ISchedule } from '../meetups-fan-sites/interfaces/fan-sites.interface';
import { cardsData } from './data/seo-tools-data';
import { emptyScheduleData, scheduleData } from './data/seo-tools-schedule-data';

@Component({
  selector: 'seo-workshops',
  templateUrl: './seo-workshops.component.html',
  styleUrl: './seo-workshops.component.scss',
})
export class SeoWorkshopsComponent {
  @Input() visibleHeader: boolean = true;
  @Input() showSchedule: boolean = true;
  cards: ICard[] = cardsData;
  schedule: ISchedule[] = scheduleData;
  constructor(private route: ActivatedRoute, private router: Router) {}
  cardsExhibit: ICard[] = cardsExhibitData;
  emptyScheduleData: IEmptySchedule[] = emptyScheduleData;
  scrollClicks(fragment: string) {
    this.router.navigate([this.router.url.includes('seo-workshops') ? '/meetups/seo-workshops' : '/meetups'], {
      relativeTo: this.route,
      fragment: fragment,
    });
  }

  exhibitNowClick() {
    this.router.navigate(['/meetups/seo-workshops/reserve-table'], {
      relativeTo: this.route,
    });
  }
}
