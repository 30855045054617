import { ICard } from '../interfaces/fan-sites.interface';

export const cardsData: ICard[] = [
  {
    id: 1,
    imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/insights.svg',
    title: 'Fan Sites Insights',
    description: 'Discover more about the ever-growing world of fan sites',
  },
  {
    id: 2,
    imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/dedicated-speakers.svg',
    title: 'Dedicated Speakers',
    description: 'Attend speeches from the biggest names in the industry',
  },
  {
    id: 3,
    imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/exclusive-zone.svg',
    title: 'Exclusive Zone',
    description: 'Take a break from the Expo to join the fan sites universe',
  },
];
