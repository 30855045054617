import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICard } from '../interfaces/meetups-workshops.interface';

@Component({
  selector: 'meetups-workshops-card',
  templateUrl: './meetups-workshops-card.component.html',
  styleUrl: './meetups-workshops-card.component.scss',
})
export class MeetupsWorkshopsCardComponent {
  @Input() card!: ICard;

  constructor(private route: ActivatedRoute, private router: Router) {}

  onClick() {
    this.router.navigate([this.card.sector.toLowerCase()]);
  }
}
