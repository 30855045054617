import { ICard } from '../../meetups-fan-sites/interfaces/fan-sites.interface';

export const cardsData: ICard[] = [
  {
    id: 1,
    imageUrl: 'https://cdn-images.island-conference.com/meetups/seo-meetups/expert_advice.svg',
    title: 'Expert Advice',
    description: 'Expand your SEO knowledge and meet some of the most influential people in the field!',
  },
  {
    id: 2,
    imageUrl: 'https://cdn-images.island-conference.com/meetups/seo-meetups/hands-on-approach.svg',
    title: 'Hands-On Approach',
    description: `Don't miss the opportunity to learn from applied strategies and through practical examples and case studies!`,
  },
  {
    id: 3,
    imageUrl: 'https://cdn-images.island-conference.com/meetups/seo-meetups/seo_for_everyone.svg',
    title: 'SEO For Everyone',
    description: `Get introduced to the world of SEO the right way, and brush up on the fundamentals that lead to success!`,
  },
];
