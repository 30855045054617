import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MeetupsFanSitesComponent } from '../meetups-fan-sites/fan-sites.component';
import { SeoWorkshopsComponent } from '../seo-workshops/seo-workshops.component';
import { MeetupsComponent } from './meetups.component';

const routes: Routes = [
  {
    path: '',
    component: MeetupsComponent,
    data: {
      className: 'meetups',
    },
  },
  // {
  //   path: 'workshops',
  //   component: MeetupsWorkshopsComponent,
  // },
  // {
  //   path: 'workshops/:sector',
  //   component: MeetupsWorkshopsSectorComponent,
  // },
  {
    path: 'fan-sites',
    component: MeetupsFanSitesComponent,
    data: {
      className: 'meetups-fan-sites',
      title: 'i-Con Meetups, Fan Sites Meetup |',
      description:
        'Join industry experts as they share the secrets of their success in the fan sites world! Find out all there is to know about this rising trend!',
      url: 'https://island-conference.com/meetups/fan-sites',
      type: 'website',
    },
  },
  // {
  //   path: 'fan-sites/reserve-table',
  //   component: ReserveTableComponent,
  //   data: {
  //     className: 'fansite-reserve-table',
  //     title: 'i-Con Meetups, Fan Sites Meetup |',
  //     description:
  //       'Join industry experts as they share the secrets of their success in the fan sites world! Find out all there is to know about this rising trend!',
  //     url: 'https://island-conference.com/meetups/fan-sites/reserve-table',
  //     type: 'website',
  //     headerText: "FAN SITES' TABLE RESERVATION",
  //     page: 'fan-sites',
  //   },
  // },
  {
    path: 'seo-workshops',
    component: SeoWorkshopsComponent,
    data: {
      className: 'seo-workshops',
      title: ' i-Con Meetups, SEO Workshops |',
      description: 'Visit the meetup zone on the second day of the conference and gain valuable knowledge from the world of search engine optimization!',
      url: 'https://island-conference.com/meetups/seo-workshops',
      type: 'website',
    },
  },
  // {
  //   path: 'seo-workshops/reserve-table',
  //   component: ReserveTableComponent,
  //   data: {
  //     className: 'seo-workshops',
  //     title: ' i-Con Meetups, SEO Workshops |',
  //     description: 'Visit the meetup zone on the second day of the conference and gain valuable knowledge from the world of search engine optimization!',
  //     url: 'https://island-conference.com/meetups/seo-workshops/reserve-table',
  //     type: 'website',
  //     headerText: 'SEO WORKSHOPS TABLE RESERVATION',
  //     page: 'seo-workshops',
  //   },
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MeetupsRoutingModule {}
