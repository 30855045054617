import { IEmptySchedule, ISchedule, ISpeaker } from '../interfaces/fan-sites.interface';

export const welcomeSpeakers: ISpeaker[] = [
  {
    name: 'Andy Wullmer',
    position: 'Traffic Captain - ModelSearcher',
    imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/Andy.png',
    companyImgUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/model-searcher-logo_new.svg',
    company2ImgUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/creator-traffic-logo.png',
  },
  {
    name: 'Michael Ruel',
    position: 'CEO - CreatorTraffic',
    imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/Michael.png',
    companyImgUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/traffic-logo.png',
  },
];

export const scheduleData: ISchedule[] = [
  {
    id: 1,
    time: '11:10 a.m. - 11:40 a.m.',
    speaker: {
      name: 'Andy Wullmer',
      position: 'Traffic Captain - ModelSearcher',
      bio: `Andy Wullmer is a known veteran in the adult industry, having established a name for himself over the past two decades. He specializes in many verticals including online retail, mobile offers, dating, live cams/model managing, and traffic brokering. He was nicknamed the "TrafficCaptain" as he is widely recognized as an industry-leading expert!`,
      topic: `How To Grow Your OnlyFans Model Successfully And Fast With Paid Traffic`,
      imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/Andy.png',
      companyImgUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/model-searcher-logo_new.svg',
      company2ImgUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/creator-traffic-logo.png',
    },
  },
  {
    id: 2,
    time: '11:45 a.m. - 12:15 p.m.',
    speaker: {
      name: 'Michelle Marriott',
      position: 'Founder - Carla Sez',
      bio: `Michelle Marriott has built an extensive career in adult-based services, offering a broad and knowledgeable insight into the industry. With a proven track record in innovation and adept management of processes and systems, Michelle has navigated the industry's radical changes over the years.
      She emphasizes expert consultancy and model management as essential components in achieving optimal marketing, organization, and profit potential within the service sector. Renowned for her helpfulness, accessibility, and unwavering commitment to support and mentorship at all levels, Michelle has collaborated closely with numerous large, well-known cam studios and companies.`,
      topic: `Model management and support for the Fansite business`,
      imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/Michelle.png',
      companyImgUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/Carla-Sez-logo.png',
    },
  },

  {
    id: 3,
    time: '12:20 p.m. - 12:50 p.m.',
    speaker: {
      name: 'Sanita Catovic',
      position: 'Model Manager - ModelSearcher',
      bio: `Based on eight years of experience in the industry, four of which have been with Captain Andy's team and extraordinary projects, my area of expertise involves working directly with content creators and webcam models to optimize their brands and achieve measurable success indicators and results.`,
      topic: `Expanding Your Audience to Genuine Customers: Getting the Most Out of ModelSearcher.com for Best OnlyFans Productivity!`,
      imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/Sanita.png',
      companyImgUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/model_searcher.png',
    },
  },
  // {
  //   id: 4,
  //   time: '14:00 p.m. - 14:30 p.m.',
  //   speaker: {
  //     name: 'Andra Chirnogeanu',
  //     position: 'Head of Business Development - my.club',
  //     bio: `Andra Chirnogeanu is a seasoned professional with over a decade of experience in the adult entertainment industry. As the Head of Business Development for Stripchat and my.club, she excels in driving growth and fostering innovation. With a law degree, Andra brings a unique perspective to her role, leveraging her legal expertise to navigate complex business landscapes. Renowned for her contributions, she has garnered numerous personal awards and has been instrumental in the success of the companies she represents. Passionate about empowering creators and advocating for positive change within the industry, Andra is dedicated to fostering growth and promoting ethical practices.`,
  //     topic: `How to make profit as Models or Agency with my.club`,
  //     imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/Andra.png',
  //     companyImgUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/my-club.png',
  //   },
  // },
  {
    id: 5,
    time: '14:35 p.m. - 15:05 p.m.',
    speaker: {
      name: 'Uwe Ruck',
      position: 'CEO - 4based.com',
      bio: `Uwe R. is known by his nickname Rucki. Has been active in the digital market since 2003 and has worked with brands such as red-tube.com, amarotic.com and cashalizer.com.
      4based.com launched at the end of 2021, is a very trendy, fast-growing, successful international fan site. It is the latest product of the company FTB MEDIA AG based in Switzerland. 4based not only offers creators the opportunity to benefit from the platform but also national and international traffic networks, TV channel streaming services and cooperation partners.
      The integrated affiliate system and attractive countries-specific payment options make 4based unique among the fan pages.`,
      topic: `Why alternatives to OnlyFans make sense for more profit`,
      imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/Uwe.png',
      companyImgUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/4based-logo.png',
    },
  },
  // {
  //   id: 6,
  //   time: '15:10 p.m. - 15:40 p.m.',
  //   speaker: {
  //     name: 'Caitlyn Brooks',
  //     position: 'Brand Ambassador - Warehouse X',
  //     bio: `A captivating webcam model renowned for tantalizing performances and fan engagement on streamate. This independent creator and model searcher ambassador has a captivating presence and performative personality. She has garnered a devoted fan base over the last 9 years in the industry, focusing on Brand refinement among others. She brings with her a wealth of experience and a passion for community and brand building. With a magnetic personality and innovative talent, Caitlyn, continues to captivate audiences as she takes on both the business and talent side of our industry, setting new standards of excellence in her field.`,
  //     topic: `The view of a Model on the Fan site business and how to promote it.`,
  //     imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/Caitlyn.png',
  //     companyImgUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/schedule/warehouse-x-logo.png',
  //   },
  // },
];

export const emptyScheduleData: IEmptySchedule[] = [
  {
    time: '13:00 p.m. - 14:30 p.m. Break',
  },
];
