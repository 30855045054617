<div class="seo-workshops-header" *ngIf="visibleHeader">
    <h3>Maximize your SEO knowledge through</h3>
    <h1>i-CON'S WORKSHOPS!</h1>
</div>

<div class="container">

    <div class="headings">
        <span>CONNECT AND GAIN VALUABLE KNOWLEDGE</span>
        <span>FROM INDUSTRY EXPERTS</span>
    </div>

    <div class="textParagraph">
        <p>Visit the MeetUp Zone on the second day of i-Con and take part in specialized SEO workshops designed to help
            industry professionals elevate their search engine optimization skills! Find out how SEO can improve your
            brand's online visibility through fresh insights, trends, and strategies, guaranteed to further optimize
            your digital marketing efforts. Starting from 12:00 p.m. workshops will last approximately 1 hour so, don't
            pass up the chance to develop new skills, learn more about SEO, and unlock a world of possibilities through
            i-Con's newest addition!</p>
    </div>

    <div class="cards">
        <fan-sites-card *ngFor="let card of cards" [card]="card"></fan-sites-card>
    </div>

    <!-- <div class="buttons">
        <a (click)="scrollClicks('seo-table')"><button class="main-btn">Exhibit Now</button></a>
        <a (click)="scrollClicks('schedule')"><button class="main-btn">View Schedule</button></a>
    </div> -->

    <div class="poweredBy">
        <span>powered by</span>
        <div class="images">
            <img src="https://cdn-images.island-conference.com/meetups/seo-meetups/white-press.svg" alt="White Press">

        </div>
    </div>

    <!-- <div class="exhibitors">
        <span>Exhibitors</span>
        <div class="images">
            <img src="https://cdn-images.island-conference.com/exhibitors/logos/redwalking.svg" alt="Redwalking">
        </div>
    </div> -->

</div>

<!-- <section id="seo-table" class="exhibit">
    <div class="container">

        <div class="detailsContainer">
            <div class="details">
                <span>RESERVE YOUR <br><strong>SEO WORKSHOP TABLE!</strong></span>
                <div class="exhibitImg">
                    <img src="https://cdn-images.island-conference.com/meetups/seo-meetups/seo-workshop.png"
                        alt="SEO Workshop Table">
                </div>
            </div>
        </div>

        <div class="cardsExhibit">
            <div class="container">
                <div class="cards">
                    <fan-sites-card [ngClass]="{'first': i === 0}" *ngFor="let card of cardsExhibit; let i = index"
                        [card]="card"></fan-sites-card>
                </div>
                <p><strong>€500</strong></p>

                <div class="buttonsExhibit">
                    <button class="main-btn" (click)="exhibitNowClick()"> Exhibit Now</button> -->
<!-- <button class="floor">View Floor Plan</button> -->
<!-- </div>
            </div>

        </div>
    </div>
</section> -->

<section id="schedule" class="speakers" *ngIf="showSchedule">
    <div class="container">
        <!-- <div class="heading">
            <span>VISIT THE MEETUP ROOM AND ENGAGE IN CONVERSATIONS</span>
            <span>Learn more about the latest trends and topics in the fan sites world through our featured
                speakers!</span>
        </div> -->

        <div class="schedule">
            <p>i-Con's workshops can accommodate all of i-Con's attendees without having to worry about their expertise
                in search engine optimization. In each of the below scheduled time slots, participants will get the
                opportunity to meet, discuss, and learn from experts in the SEO field:
            </p>

            <div class="scheduleFlex">
                <fan-sites-schedule class="schedule" *ngFor="let s of schedule | slice:0:1; let i = index;"
                    [schedule]="s"></fan-sites-schedule>
            </div>


            <div class="emptySlots">
                <div class="empty" *ngFor="let slot of emptyScheduleData | slice:0:1">
                    <hr>
                    <div class="line break">
                        <hr>
                        <span>{{slot.time}}</span>
                        <hr>
                    </div>
                </div>
            </div>

            <div class="scheduleFlex">
                <fan-sites-schedule class="schedule secondHalf" *ngFor="let s of schedule | slice:1;let i = index;"
                    [ngClass]="{'first': i === 0}" [hasLocation]="i === 1" [schedule]="s"></fan-sites-schedule>
            </div>

            <hr>
        </div>
    </div>
</section>