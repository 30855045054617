import { Component, Input } from '@angular/core';
import { ICard } from '../interfaces/fan-sites.interface';

@Component({
  selector: 'fan-sites-card',
  templateUrl: './fan-sites-card.component.html',
  styleUrls: ['./fan-sites-card.component.scss'],
})
export class FanSitesCardComponent {
  @Input() card!: ICard;
}
