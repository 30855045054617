<div class="card" (click)="onClick()">
    <img class="mainImg" src="{{card.imageUrl}}" alt="{{card.sector.replace('meetups/', '').replace('-',' ')}}">
    <div class="phrasesContainer">
        <div class="phrases {{card.phraseColour}}">
            <span>{{card.phrase1}}</span>
            <span class="phrase2">{{card.phrase2}}</span>
            <span class="description" *ngIf="card.description">{{card.description}}</span>
        </div>
    </div>

    <div class="bottomDetails" *ngIf="card.time">
        <div class="time">
            <img src="https://cdn-images.island-conference.com/meetups/workshops/clock.svg" alt="Time">
            <span>{{card.time}}</span>
        </div>
        <div class="poweredBy" *ngIf="card.poweredByUrl">
            <span>powered by: <img src="{{card.poweredByUrl}}" alt="{{card.poweredBy}}"></span>
        </div>
    </div>
</div>