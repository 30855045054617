import { IEmptySchedule, ISchedule } from '../../meetups-fan-sites/interfaces/fan-sites.interface';

export const scheduleData: ISchedule[] = [
  // {
  //   id: 1,
  //   time: '11:00 a.m. - 11:50 a.m.',
  //   speaker: {
  //     name: 'Sobhi Smat',
  //     position: 'SEO Consultant - Sobhi Smat',
  //     bio: `Sobhi Smat is the SEO Manager at Binance, leading innovative SEO link-building strategies for the world's largest cryptocurrency exchange. With 8+ years of experience in e-commerce and Web3, Sobhi specializes in off-page SEO, Digital PR, and content marketing. With advanced analytics and optimization techniques, Sobhi drives web traffic, conversions, and revenue, contributing to Binance's global success in the Web3 industry.`,
  //     topic: `Inceptional Gaps: Analyzing Competitor Dreams`,
  //     imageUrl: 'https://cdn-images.island-conference.com/meetups/seo-meetups/schedule/Sobhi.png',
  //     companyImgUrl: 'https://cdn-images.island-conference.com/meetups/seo-meetups/schedule/sobhi_smat_logo.png',
  //   },
  // },
  {
    id: 2,
    time: '12:00 p.m. - 12:50 p.m.',
    speaker: {
      name: 'Milosz Krasinski',
      position: 'CEO - Chillifruit',
      bio: `Miłosz Krasiński is a seasoned digital marketing and SEO expert with a proven track record of driving growth and innovation in the online space. As the founder of Chilli Fruit Web Consulting, he has led transformative campaigns, elevating brand presence and profitability for a diverse clientele. Renowned for his strategic insights and contributions to major industry publications, Miłosz combines cutting-edge techniques with a deep understanding of digital trends to deliver unparalleled results. His dedication to mentoring and industry advancement underscores his position as a leading voice in digital marketing and SEO.`,
      topic: `From Taboo to Trending - Transforming Adult Content Visibility with HARO`,
      imageUrl: 'https://cdn-images.island-conference.com/meetups/seo-meetups/schedule/Milosz.png',
      companyImgUrl: 'https://cdn-images.island-conference.com/meetups/seo-meetups/schedule/chilli_fruit_logo.png',
    },
  },
  {
    id: 3,
    time: '14:00 p.m. - 14:50 p.m.',
    speaker: {
      name: 'Ivana Flynn',
      position: 'SEO Director - Soft2Bet',
      bio: `Ivana has 15 years of SEO experience across very competitive niches, having been in the gaming niche for 8 years now. She is a seasoned conference speaker, covering the top iGaming conferences such as IGB, ICE, SBC, and Sigma.
      She has spoken at AWSummit and BrightonSEO. Additionally, she is a published author on SEO topics and has been featured in several podcasts discussing marketing and SEO.
      She also serves as a co-host for the marketing segment of the SBC podcast.`,
      topic: `The Underbelly of SEO: Unveiling Parasitic Practices`,
      imageUrl: 'https://cdn-images.island-conference.com/meetups/seo-meetups/schedule/Ivana.png',
      companyImgUrl: 'https://cdn-images.island-conference.com/meetups/seo-meetups/schedule/soft2bet logo.png',
    },
  },
  {
    id: 4,
    time: '15:00 p.m. - 15:50 p.m.',
    speaker: {
      name: 'Koray Tuğberk Gübür',
      position: 'CEO - Holistic SEO & Digital',
      bio: `Koray Tuğberk Gübür is an expert in SEO and digital marketing, known for his deep insights into search engine optimization strategies. His extensive experience includes working with various industries to enhance online visibility and drive traffic growth. As a thought leader, Koray frequently shares his knowledge through articles, webinars, and workshops, focusing on advanced SEO techniques and content optimization.`,
      topic: `Talk to the Blind Librarian`,
      imageUrl: 'https://cdn-images.island-conference.com/meetups/seo-meetups/schedule/Koray.png',
      companyImgUrl: 'https://cdn-images.island-conference.com/meetups/seo-meetups/schedule/holistic-seo-logo.png',
    },
  },
  {
    id: 5,
    time: '16:00 p.m. - 16:50 p.m.',
    speaker: {
      name: 'Maja Jovančević',
      position: 'Head of SEO - GiG',
      bio: `Maja is a seasoned SEO expert, specializing in affiliate SEO in the gambling industry. Her approach to SEO is holistic and she believes knowledge, logic and problem-spotting are her main assets. As a manager, she prioritizes building up her team's knowledge, transferring skills and inspiring employees, resulting in her team's remarkable success in their field.`,
      topic: `What Happens to Your Website After you Go Viral - The NFT Campaign Story`,
      imageUrl: 'https://cdn-images.island-conference.com/meetups/seo-meetups/schedule/Maja.png',
      companyImgUrl: 'https://cdn-images.island-conference.com/meetups/seo-meetups/schedule/gig_logo.png',
    },
  },
];

export const emptyScheduleData: IEmptySchedule[] = [
  {
    time: '13:00 p.m. - 14:00 p.m. Break',
  },
];
