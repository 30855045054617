import { ICard } from '../interfaces/fan-sites.interface';

export const cardsExhibitData: ICard[] = [
  {
    id: 1,
    imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/high-chairs-high-table.svg',
    description: '2x High Chairs & High Table',
  },
  {
    id: 2,
    imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/branded-wall.svg',
    description: 'Large Branded Printing',
  },
  {
    id: 3,
    imageUrl: 'https://cdn-images.island-conference.com/meetups/fan-sites/exposure.svg',
    description: 'Exposure in the meetup area',
  },
];
