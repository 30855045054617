<div class="meetup-header">
    <h1>i-CONIC MEETUPS!</h1>
</div>
<div class="meetups-container">
    <div class="content">
        <p>The Island Conference Meetups is brought to you by specialists across multiple fields in the digital
            marketing world, ensuring a priceless experience for you and your company. i-Con's Meetups are dedicated to
            our attendees who wish to get something more from their conference experience! By joining, you'll be able to
            discover exciting and practical ways to drive traffic to your campaigns. </p>

        <p>All attendees are welcome to participate in our Fan Sites MeetUp, scheduled on the first day of the
            conference, and learn more through our SEO workshops, during the second day. What's more, in collaboration
            with Nexxie Group and The Haze Team, C-level executives, business owners, and super affiliates can
            experience a once in a lifetime trip, through our Miconos Meetup!</p>

        <p>Every year we will upgrade our meetups, covering a larger variety of topics and introducing even more
            experts across the affiliation sector.</p>

        <p><strong> So, don't wait up, visit our dedicated Meetup Zone right by our speaker stages to get a taste of
                what i-Con has to offer!</strong></p>
    </div>

    <div class="wrapper">
        <div class="container">
            <h2>Find Out More About What The I-Con Meetups Have To Offer!</h2>
            <div class="cards">
                <meetups-workshops-card *ngFor="let card of cards; let i = index;" [card]="card"
                    [ngClass]="{'miconos': i === 2}"></meetups-workshops-card>
            </div>
        </div>
    </div>

</div>