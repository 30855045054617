<div class="fansites-header" *ngIf="visibleHeader">
    <h3>JOIN OUR</h3>
    <h1>FAN SITES MEETUP!</h1>
</div>

<div class="container">

    <div class="headings">
        <span>DISCOVER THE WORLD OF FAN SITES</span>
        <span>ON THE 1ST DAY OF THE CONFERENCE</span>
    </div>

    <div class="textParagraph">
        <p>Join some of the most influential individuals in the fan-site world! Visit i-Con's MeetUp Room located next
            to the Speakers' area, on the first day of the conference. Join other platform operators, Service Providers
            and Traffic Sources including the renowned Creator Traffic! Gain valuable insights on the fastest growing
            niche in our industry and find out how you can take advantage of the hype!</p>
    </div>

    <div class="cards">
        <fan-sites-card *ngFor="let card of cards" [card]="card"></fan-sites-card>
    </div>

    <div class="buttons">
        <!-- <a (click)="scrollClicks('meetup-table')"><button class="main-btn">Exhibit Now</button></a> -->
        <a (click)="scrollClicks('schedule')"><button class="main-btn">View Schedule</button></a>
    </div>

    <div class="poweredBy">
        <span>powered by</span>
        <div class="images">
            <img src="https://cdn-images.island-conference.com/meetups/fan-sites/creator-traffic-logo.png"
                alt="Creator Traffic">
            <img src="https://cdn-images.island-conference.com/meetups/fan-sites/model-searcher-logo_new.svg"
                alt="Model Searcher">
        </div>
    </div>

    <div class="exhibitors">
        <span>Exhibitors</span>
        <div class="images">
            <img src="https://cdn-images.island-conference.com/meetups/fan-sites/instafans.png" alt="Instafans">
            <img src="https://cdn-images.island-conference.com/meetups/fan-sites/creatorhero.png" alt="CreatorHero">
            <!-- <img src="https://cdn-images.island-conference.com/exhibitors/logos/vdesk.svg" alt="Vdesk"> -->
        </div>
    </div>

</div>

<section id="meetup-table" class="exhibit">
    <div class="container">

        <div class="detailsContainer">
            <div class="details">
                <span>ARE YOU A PART OF THE FAN-SITE WORLD?<br>BOOK YOUR SPOT!</span>
                <span>If you're a fan site owner interested in exhibiting your own brand throughout these valuable
                    insights, you can reserve your own dedicated space.</span>
                <span>RESERVE YOUR <br><strong>FAN SITES' MEETUP TABLE!</strong></span>
                <div class="exhibitImg">
                    <img src="https://cdn-images.island-conference.com/meetups/fan-sites/exhibit.png" alt="Exhibit">
                </div>
            </div>
        </div>

        <div class="cardsExhibit">
            <div class="container">
                <div class="cards">
                    <fan-sites-card [ngClass]="{'first': i === 0}" *ngFor="let card of cardsExhibit; let i = index"
                        [card]="card"></fan-sites-card>
                </div>
                <p><strong>€500</strong></p>

                <div class="buttonsExhibit">
                    <!-- <button class="main-btn" (click)="exhibitNowClick()"> Exhibit Now</button> -->
                    <button disabled class="main-btn">Sold
                        Out</button><!--  will also comment out the route to reserve table-->
                    <!-- <button class="floor">View Floor Plan</button> -->
                </div>
            </div>

        </div>
    </div>
</section>

<section id="schedule" class="speakers" *ngIf="showSchedule">
    <div class="container">
        <div class="heading">
            <span>Speeches will begin at 11:00am - with a different one taking place every half an hour! Below you can
                find out more about our featured schedule:</span>
            <hr>
        </div>


        <div class="welcome">
            <p>11:00 a.m. - 11:10 a.m.</p>
            <h2>Welcome Speech and Info</h2>

            <div class="speeches">
                <div class="speaker" *ngFor="let speaker of welcomeSpeakers">
                    <img [src]="speaker.imageUrl" alt="">
                    <h2>{{speaker.name}}</h2>
                    <p>{{speaker.position}}</p>
                    <img [src]="speaker.companyImgUrl" alt="Model Searcher">
                    <img *ngIf="speaker.company2ImgUrl" [src]="speaker.company2ImgUrl" alt="Creator Traffic">
                </div>
            </div>
        </div>


        <div class="scheduleFlex">
            <fan-sites-schedule class="schedule" *ngFor="let s of schedule | slice:0:3"
                [schedule]="s"></fan-sites-schedule>
        </div>


        <div class="emptySlots">
            <div class="empty" *ngFor="let slot of emptyScheduleData | slice:0:1; let i = index"
                [ngClass]="{'break': i === 0}">
                <hr>
                <div class="line">
                    <hr>
                    <span>{{slot.time}}</span>
                    <hr>
                </div>
            </div>
        </div>

        <div class="scheduleFlex">
            <fan-sites-schedule class="schedule secondHalf" *ngFor="let s of schedule | slice:3;let i = index;"
                [ngClass]="{'first': i === 0}" [schedule]="s"></fan-sites-schedule>
        </div>

        <div class="emptySlots last">
            <div class="empty" *ngFor="let slot of emptyScheduleData | slice:1">
                <hr>
                <div class="line">
                    <hr>
                    <span>{{slot.time}}</span>
                    <hr>
                </div>
            </div>
        </div>

        <hr>
    </div>
</section>