import { Component } from '@angular/core';
import { cardsData } from './data/meetups-data';

@Component({
  selector: 'i-con.app-meetups',
  templateUrl: './meetups.component.html',
  styleUrls: ['./meetups.component.scss'],
})
export class MeetupsComponent {
  class_name = 'meetups';
  phrase = '';
  title1 = `i'CONIC MEETUPS!`;

  cards = cardsData;
}
