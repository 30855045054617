import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cardsData } from './data/fan-sites-data';
import { cardsExhibitData } from './data/fan-sites-exhibit-data';
import { emptyScheduleData, scheduleData, welcomeSpeakers } from './data/fan-sites-schedule-data';
import { ICard, IEmptySchedule, ISchedule, ISpeaker } from './interfaces/fan-sites.interface';

@Component({
  selector: 'meetups-fan-sites',
  templateUrl: './fan-sites.component.html',
  styleUrls: ['./fan-sites.component.scss'],
})
export class MeetupsFanSitesComponent {
  constructor(private route: ActivatedRoute, private router: Router) {}

  @Input() visibleHeader: boolean = true;
  @Input() showSchedule: boolean = true;
  class_name = 'meetups-fan-sites';
  phrase = 'JOIN OUR';
  title1 = `FAN SITES MEETUP!`;
  cards: ICard[] = cardsData;
  cardsExhibit: ICard[] = cardsExhibitData;
  schedule: ISchedule[] = scheduleData;
  welcomeSpeakers: ISpeaker[] = welcomeSpeakers;
  emptyScheduleData: IEmptySchedule[] = emptyScheduleData;
  scrollClicks(fragment: string) {
    this.router.navigate([this.router.url.includes('fan-sites') ? '/meetups/fan-sites' : '/meetups'], {
      relativeTo: this.route,
      fragment: fragment,
    });
  }

  exhibitNowClick() {
    this.router.navigate(['/meetups/fan-sites/reserve-table'], {
      relativeTo: this.route,
    });
  }
}
